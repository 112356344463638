.nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f7f7f7;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btnLogout {
    padding: 0.5rem 1rem;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
}
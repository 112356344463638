.authContainer {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10%;
  }
  
  .authRow {
    width: 100%;
    max-width: 30rem;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
.topNav{

    margin-top:10px;
    display:flex;
    justify-content: flex-start;
    margin-bottom: 50px;
}



.logo-image{
    width:20%
}

.count-button{
    border: 1px solid lightgray;
}

.cross-button{
    border: 1px solid lightgray;
}


.submit-button{
    background-color: #0c9347
}

.ml-2{
    margin-left: 5px;
}

.dropdown-select {
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 0;
  }

  .table-responsive{
    overflow: visible !important;
  }


  .formContainer {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10%;
  }
  
  .formRow {
    width: 100%;
    max-width: 30rem;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  @media(max-width: 767px){
    .md-mb-10{
        margin-bottom: 10px;
    }
  }